import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.js";
import { css } from "@emotion/core";
import SEO from "../components/seo";
import Helmet from "react-helmet";
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';

const CountryTemplate = ({ data }) => (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
      />
      <Helmet defer={false}>
        {data.wordpressPage.acf.country_background != null &&
          <style type="text/css">{`
              body {
                  background-image: url(`+data.wordpressPage.acf.country_background.localFile.publicURL+`);
                  background-size: cover;
                  background-position: center center;
              }
          `}</style>
          }
      </Helmet>
      <header className="mainHead flexContainer portfolioHead faCenter fjCenter" css={css`
        background: ${data.wordpressPage.acf.brand_color};
        `}>      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to={`/`}>
            <span className="arrowbk"><Icon>arrow_back</Icon></span> Home
          </Link>

      {/* Nav Side */}


      <Navlinks />
      </div>
      </header>
      <section id="category-content" className="flexContainer secondaryTab">
      <div className="fCol12 maxTablet">
        <div className="flexContainer categoryInner">
        {data.wp.pages.edges.map(mainCats => (
            <React.Fragment>
            {mainCats.node.childPages.edges.map(cats => (
              <React.Fragment>
                {cats.node.childPages.edges.length > 0 &&
                <a id="" className="fCol12 catMain flexContainer" href={'/'+cats.node.uri}>
                    <div className="categoryIcon"><span className={cats.node.category_page_fields.categoryIcon +' catIcon'}><span className="path1"/><span className="path2"/><span className="path3"/><span className="path4"/><span className="path5"/><span className="path6"/><span className="path7"/></span></div>
                    <div className="categoryName">{cats.node.category_page_fields.categoryName}</div>
                </a>
                }
                {cats.node.childPages.edges.length == 0 &&
                <div className="fCol12 catMain flexContainer disabled">
                    <div className="categoryIcon"><span className={cats.node.category_page_fields.categoryIcon +' catIcon'}><span className="path1"/><span className="path2"/><span className="path3"/><span className="path4"/><span className="path5"/><span className="path6"/><span className="path7"/></span></div>
                    <div className="categoryName">{cats.node.category_page_fields.categoryName}</div>
                </div>
                }
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        </div>
        <a href={'/' + data.wordpressPage.slug + '/all-brands'}><button className="allBrandsButton">All Brands</button></a>
      </div>
    </section>

    </Layout>
  )
  
  export default CountryTemplate
  
  export const query = graphql`
    query($id: Int!) {
      allWordpressPage(filter: {wordpress_parent: {eq: $id}}, sort: {fields: acf___cat_sort_order, order: ASC}) {
        edges {
          node {
            acf {
              category_icon
              category_name
              cat_sort_order
            }
            path
          }
        }
      }
      wordpressPage(wordpress_id: { eq: $id }) {
        title
        excerpt
        content
        parent_element {
          title
          slug
        }
        slug
        acf {
          country_cats {
            cnt_cat_group {
              select_category_icon
              select_cat_page {
                post_title
                post_name
              }
            }
          }
          country_background {
            localFile {
              publicURL
            }
          }
        }
      }
      wp {
        pages(where: {id: $id}) {
          edges {
            node {
              childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                edges {
                  node {
                    title
                    childPages {
                      edges {
                        node {
                          title
                          pageId
                        }
                      }
                    }
                    category_page_fields {
                      categoryIcon
                      categoryName
                    }
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
`